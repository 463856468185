.app-container{
    position: fixed;
    background-color: var(--mdb-gray-100);
    height: 100vh;
    width: 100%;
}

.work-area{
    height: calc(100vh - 50px);
    overflow: auto;
    padding: 20px;
    background-color: var(--mdb-white);
}