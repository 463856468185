.verify-loader{
    height: 100px !important;
    width: 100px !important;
}

.login-form {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    margin: auto;
    text-align: center;
    margin-top: calc(50vh - 180px);
}

.err-msg{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color:var(--error-text-color);
    font-size: 16px;
    margin-top: 5px;
}
.error-block{
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color:var(--mdb-danger);
    font-size: 16px;
    margin-top: 5px;
}