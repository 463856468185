
/* Sidebar */
.sidebar {
    position: fixed;
    top: 93px;
    bottom: 0;
    left: 0;
    padding: 0;
    /* Height of navbar */
    height: 100vh !important;
    /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%); */
    width: 240px !important;
    /* z-index: 600; */
    transition: all 0.3s ease-in-out;
    z-index: 99;
}

.sidebar .navbar-toggler.close-nav{
    position: absolute;
    left: 222px;
    top: 62px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px;
    box-shadow: 3px 0 2px var(--light-shadow-color);
    border: solid 1px var(--mdb-danger);
}
.sidebar .navbar-toggler{
    display: none;
}

.sidebar .navbar-brand{
    padding: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 80px !important;
    border-radius: 0 !important;
}
.sidebar .navbar-brand img{
    width: 78%;
    height: 100%;
    object-fit: contain;
    top: 13px !important;
}

.sidebar.show {
    width: 240px !important;
}
.sidebar.show.mini {
    width: 80px !important;
}
.sidebar.show.mini ul a {
    /* max-width: 80px !important; */
    max-height: 66px;
    height: inherit;
    transition: none;
}

.sidebar .no-mini-label {
    margin-left: 5px;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    opacity: 1;
}
.sidebar.show.mini .no-mini-label {
    transition: all 0.3s ease-in-out;
    font-size: 1px;
    opacity: 0;
}

.sidebar .menuTitle{
    position: relative;
    left: 9px;
    transition: all 0.3s ease-in-out;
}

.sidebar .active {
border-radius: 5px;
/* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
}

.sidebar .menu-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 50px);

}
.sidebar .menu-container .menu-header{
    height: 65px;
}
.sidebar .menu-container .menu-footer{
    height: 42px;
}
.sidebar .menu-container .menu-footer a{
    height: 41px !important;
    width: 100% !important;
    display: block;
    padding-top: 4px;
    color: var(--mdb-list-group-color);
    overflow: hidden;
}
.sidebar .menu-container .menu-footer ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.sidebar .menu-container .menu-body{
    flex: auto;
    height: auto;
    min-height: 250px;
}


.nested-menu ul {
    /* display: none; */
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.sidebar a:hover {
    /* background-color: rgba(var(--mdb-warning-rgb), 0.1) !important;
    color: var(----mdb-list-group-color) !important; */
    background-color: #d3d4de;
    border-radius: 0 25px 25px 0 !important;
}

.sidebar li a{
    /* font-weight: bold; */
    font-size: 16px;
    line-height: 14px;
    color: var(----mdb-list-group-color) !important;
    overflow-x: hidden;
    transition: none;
}

.nested-menu > a{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(----mdb-list-group-color);
}

.sidebar li,
/* .sidebar li a, */
.sidebar li a .anchor
.sidebar li * {
    transition: all 0.3s ease-in-out;
    overflow-y: hidden;
}
.sidebar a.active {
    /* background-color: var(--mdb-warning); */
    background-color: var(--mdb-primary) !important;
    color: var(--mdb-white) !important;
    font-size: bold;
}
.sidebar a.active:hover {
    background-color: rgba(var(--mdb-primary-rgb), 0.7) !important;
    /* color: var(--mdb-white) !important;
    font-size: bold; */
}

.sidebar ul{
    padding: 5px;
    padding-left: 0;
}

.sidebar li{
    border-radius: 0 25px 25px 0;
}

.sidebar li:hover{
    /* background-color: var(--mdb-warning-rgb) !important; */
    box-shadow: 0px 0px 5px #6f6f6f;
    border-radius: 0 25px 25px 0;
    background-color: transparent !important;
}

.nested-menu{
    height: 43px;
}

.nested-menu.open{
    height: auto;
}

.nested-menu.open > a {
    background-color: rgba(var(--mdb-warning-rgb), 0.1);
}

.nested-menu.open > a > i.anchor {
    transform: rotate(90deg);
}
.nested-menu > a > i.anchor {
    transform: rotate(0deg);
    transform-origin: center;
    font-size: 15px;
    width: 30px;
    height: 20px;
    overflow: visible;
    display: flex;
    flex-wrap: nowrap;
    place-content: center;
    align-items: center;
    padding-top: 3px;
    padding-left: 2px;
    margin-top: 4px;
}

.search-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding:5px; */
}

.search-box button{
    /* border-radius: 50%; */
    width: 35px;
    height: 35px;
    /* padding: 5px; */
    border: solid 1px var(--mdb-gray-400) !important;
    margin-left: 0px !important;
    padding: 3px;
}
.search-box button.mini{
    border-radius: 50% !important;
    margin-left: -5px !important;
}
.search-box button:hover{
    background-color: rgba(var(--mdb-warning-rgb), 0.1) !important;
}

.search-box .txtbox{
    transition: all 0.3s ease-in-out;
    width: 83%;
    height: 35px;
    opacity: 1;
    padding: 4px 8px !important;
    border: solid 1px var(--mdb-gray-400) !important;
    border-radius: 25px 0 0 25px !important;
}
.search-box .txtbox:focus,
.search-box .txtbox:active{
    border: solid 1px var(--mdb-gray-400) !important;
    outline: none;
}
.search-box .txtbox.hide{
    width: 0 !important;
    opacity: 0;
}


.sidebar.mini .menu-icon {
    font-size: 30px;
    margin-left: calc(50% - 10px);
    margin-bottom: 5px;
    transition: all 0.3s ease-in-out;
}
.sidebar.mini .nested-menu .menu-icon {
    margin-top: 5px !important;
}
.sidebar.mini .menuTitle.mini-label {
    line-height: 9px;
    font-size: 10px;
    width: calc(100% + 16px);
    display: block;
    margin-left: -17px !important;
    text-align: center;
    transition: all 0.3s ease-in-out;
}
.sidebar.mini .anchor{
    display: block;
    position: absolute;
    left: 53px;
    margin-top: -53px;
}
.sidebar.mini .anchor{
    display: block;
    position: absolute;
    left: 53px;
    margin-top: -45px;
    transition: all 0.3s ease-in-out;
}

.sidebar.mini .sub-nav a{
    color: var(--mdb-link-color) !important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.sidebar.mini .sub-nav a.active{
    color: var(--mdb-white) !important;
}
.sidebar.mini .sub-nav a .menuTitle.mini-label{
    line-height: 0.5rem;
    margin-top: 5px;
}

.show-in-mobile{
    display: none;
}
.hide-in-mobile{
    display: block;
}


.sidebar.show ul > li > div.list-group-item{
    background-color: transparent !important;
}
.sidebar.show ul > li > div.list-group-item .fas{
    font-size: 18px !important;
    margin-right: 0 !important;
}

@media (max-width: 992px) {
    .sidebar {
        width: 240px;
        top: 0 !important;
    }
    .sidebar .navbar-toggler.close-nav{
        display: block;
    }
    .sidebar .menu-container{
        height: calc(100vh - 78px);
    }
    .show-in-mobile{
        display: block !important;
    }
    .hide-in-mobile{
        display: none !important;
    }
}

@media (min-width: 992px) {
    .sidebar {
        height: 100vh !important;
        width: unset !important;
    }

    .sidebar .navbar-brand{
        display: none;
    }

    .sidebar {
        top: 50px;
    }
    .show-in-mobile{
        display: none !important;
    }
    .hide-in-mobile{
        display: block !important;
    }
}
